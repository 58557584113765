import * as THREE from "three";
import occlusionImage from './../img/test_text.jpeg'

var container;
var camera, scene, renderer, clock;
var uniforms;
let texture

init();
animate();

function init() {
    console.log(occlusionImage, 'occlusionImage')
    container = document.getElementById( 'container' );

    texture = new THREE.TextureLoader().load(occlusionImage);
    //texture.repeat.set( 2, 2 )
    camera = new THREE.Camera();
    camera.position.z = 1;

    scene = new THREE.Scene();
    clock = new THREE.Clock();

    var geometry = new THREE.PlaneBufferGeometry( 2, 2 );

    uniforms = {
        u_time: { type: "f", value: 1.0 },
        u_resolution: { type: "v2", value: new THREE.Vector2() },
        u_mouse: { type: "v2", value: new THREE.Vector2() },
        u_sample0: {type: 't', value: texture}
    };

    var material = new THREE.ShaderMaterial( {
        uniforms: uniforms,
        vertexShader: `        
        precision highp float;
        varying vec2 vUv;
        varying vec3 vPosition;
        float PI = 3.141592;
        varying vec3 vNormal;
        void main() {
          vUv = uv;
          vNormal = normal;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }`,
        fragmentShader: `
        #ifdef GL_ES
  precision highp float;
  #endif

  uniform vec2 u_resolution;
  uniform float u_time;
  uniform sampler2D u_sample0;

  varying vec2 vUv;
  varying vec3 vNormal;


  #define PI 3.14159265358979323846
  
  const float Scale1 = 1.;
  const float Scale2 = 3.5;
  const float Amp = 20.0;
  const float FreqX = 30.0;
  const float FreqY = 30.0;
  
  // --- noise functions from https://www.shadertoy.com/view/XslGRr
  // Created by inigo quilez - iq/2013
  // License Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.
  
  const mat3 m = mat3( 0.00,  0.80,  0.60,
                -0.80,  0.36, -0.48,
                -0.60, -0.48,  0.64 );
  
  float hash( float n )
  {
      return fract(sin(n)*43758.5453);
  }
  
  float noise( in vec3 x )
  {
      vec3 p = floor(x);
      vec3 f = fract(x);
  
      f = f*f*(3.0-2.0*f);
  
      float n = p.x + p.y*57.0 + 113.0*p.z;
  
      float res = mix(mix(mix( hash(n+  0.0), hash(n+  1.0),f.x),
                          mix( hash(n+ 57.0), hash(n+ 58.0),f.x),f.y),
                      mix(mix( hash(n+113.0), hash(n+114.0),f.x),
                          mix( hash(n+170.0), hash(n+171.0),f.x),f.y),f.z);
      return res;
  }
  
  float fbm( vec3 p )
  {
      float f;
      f  = 0.5000*noise( p ); p = m*p*2.02;
      f += 0.2500*noise( p ); p = m*p*2.03;
      f += 0.1250*noise( p ); p = m*p*2.01;
      f += 0.0625*noise( p ); 
      return f;
  }
  // --- End of: Created by inigo quilez --------------------
  float mynoise ( vec3 p)
  {
  	 return noise(p);
  	 //return .5+.5*sin(50.*noise(p));
  }
  float myfbm( vec3 p )
  {
      float f;
      f  = 0.5000*mynoise( p ); p = m*p*2.02;
      f += 0.2500*mynoise( p ); p = m*p*2.03;
      f += 0.1250*mynoise( p ); p = m*p*2.01;
      f += 0.0625*mynoise( p ); p = m*p*2.05;
      f += 0.0625/2.*mynoise( p ); p = m*p*2.02;
      f += 0.0625/4.*mynoise( p );
      return f;
  }
  float myfbm2( vec3 p )
  {
      float f;
      f  = 1. - 0.5000*mynoise( p ); p = m*p*2.02;
      f *= 1. - 0.2500*mynoise( p ); p = m*p*2.03;
      f *= 1. - 0.1250*mynoise( p ); p = m*p*2.01;
      f *= 1. - 0.0625*mynoise( p ); p = m*p*2.05;
      f *= 1. - 0.0625/2.*mynoise( p ); p = m*p*2.02;
      f *= 1. - 0.0625/4.*mynoise( p );
      return f;
  }

  
  void main(){
      vec2 uv = vUv;
  	  vec2 st = gl_FragCoord.xy/u_resolution.xy;
  
      vec4 gradiantTxture = texture2D(u_sample0, uv);


      vec3 v;
      vec3 p = Scale2*vec3(uv,0.)-u_time*(1.0, 1.0, 1.0)*0.1;
      float x = myfbm(p);
      v = (.5+.5*sin(x*vec3(FreqX, FreqY, 1.0)*Scale1))/Scale1;
      v *= Amp;
  
      vec2 scaledUV = vec2( (0.3 + (uv.x / 2. ) ),( 0.6 + (uv.y / 2. )  ));
  
  
      vec2 vxResized = vec2( (0.3 + (v.x / 2. ) ),( 0.6 + (v.y / 2. )  ));
      
      vec4 camera = texture2D(u_sample0, .02*(vxResized) + scaledUV + (vNormal.xy/2.5));
      //vec3 camera = u_sample0.sample( .02*(vxResized) + scaledUV + (vNormal.xy/2.5) ).rgb.valueOr(vec3(0.0));
      
      gl_FragColor = vec4( camera.rgb , 1. );
  
  }
        `
    } );

    var mesh = new THREE.Mesh( geometry, material );
    scene.add( mesh );

    renderer = new THREE.WebGLRenderer();
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setPixelRatio( window.devicePixelRatio );

    container.appendChild( renderer.domElement );

    onWindowResize();
    window.addEventListener( 'resize', onWindowResize, false );

    document.onmousemove = function(e){
      uniforms.u_mouse.value.x = e.pageX
      uniforms.u_mouse.value.y = e.pageY
    }
}

function onWindowResize( event ) {
    renderer.setSize( window.innerWidth, window.innerHeight );
    uniforms.u_resolution.value.x = renderer.domElement.width;
    uniforms.u_resolution.value.y = renderer.domElement.height;
}

function animate() {
    requestAnimationFrame( animate );
    render();
}

function render() {
    uniforms.u_time.value += clock.getDelta();
    renderer.render( scene, camera );
}